/*
 * PAGINATION
 */
export const FILTER_SEARCH = 'q'
export const FILTER_PAGE = 'page'
export const FILTER_ITEMS_PER_PAGE = 'itemsPerPage'

export const FILTER_TRANSLATIONS_NAME = 'translations.name'
export const FILTER_COMPANY = 'company'
export const FILTER_SOURCE = 'source'
export const FILTER_ACTIVE = 'active'
